import * as React from 'react'
import AppStoreSVG from './images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import GooglePlaySVG from './images/google-play-badge.svg'

interface BadgeProps {
  style?: React.CSSProperties
}

export const AppStoreBadge = (props: BadgeProps) => {
  return <img src={AppStoreSVG} {...props} />
}

export const PlayStoreBadge = (props: BadgeProps) => {
  return <img src={GooglePlaySVG} {...props} />
}
