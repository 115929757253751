import * as React from "react"
import { StaticQuery } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"
import { graphql } from "gatsby"

export const IPhoneXFrame = (props: {
  style: React.CSSProperties
  children: (style: React.CSSProperties) => JSX.Element
}) => {
  return (
    <div style={props.style}>
      <StaticQuery
        query={graphql`
          {
            file(
              relativePath: { eq: "devices/iphone_mockup_empty_noshadow.png" }
            ) {
              childImageSharp {
                fixed(height: 450) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        `}
        render={data => (
          <Img
            fixed={data.file.childImageSharp.fixed}
            imgStyle={{ zIndex: 2 }}
          />
        )}
      />
      {props.children({
        position: "absolute",
        zIndex: 1,
        left: 3,
        top: 2,
        height: 447,
        borderRadius: 20,
        boxShadow: " 0px 10px 50px rgba(0,0,0,0.5)",
      })}
    </div>
  )
}
