import * as React from "react"
import { lazy, Suspense } from "react"
import { Form, Button } from "react-bootstrap"
import { sizes } from "../layouts/sizes"
import { withTranslation, WithTranslation } from "react-i18next"

// @ts-ignore
const ReCAPTCHA = lazy(() => import("react-google-recaptcha"))

interface TeamContactFormProps {
  style?: React.CSSProperties
}

interface ContactFormPresenterState {
  status: "WAITING" | "READY" | "SUCCESS" | "ERROR"
  consentGiven?: boolean
}

class ContactFormPresenter extends React.Component<
  TeamContactFormProps & WithTranslation,
  ContactFormPresenterState
> {
  state: ContactFormPresenterState = {
    status: "WAITING",
  }

  captcha?: typeof ReCAPTCHA

  captchaChanged(value: any) {
    this.setState({ status: "READY" })
  }

  renderCaptcha() {
    if (this.state.consentGiven) {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ReCAPTCHA
            ref={(ref: any) => (this.captcha = ref)}
            sitekey="6LeTYccUAAAAAIEuJXqYgo-kyAF9lq6_jy6NZM96"
            onChange={this.captchaChanged.bind(this)}
          />
        </Suspense>
      )
    } else {
      return <></>
    }
  }

  renderSubmitButton() {
    return (
      <Button variant="light" type="submit">
        {this.props.t("contact.submit")}
      </Button>
    )
  }

  renderFormAction() {
    const { t } = this.props

    switch (this.state.status) {
      case "WAITING":
      case "READY": {
        return (
          <div>
            {this.renderCaptcha()}
            <div style={{ marginTop: sizes.margins.normal }} />
            {this.state.status == "READY" && this.renderSubmitButton()}
          </div>
        )
      }
      case "SUCCESS": {
        return (
          <p style={{ marginTop: sizes.margins.normal }}>
            {t("contact.thanks")}
          </p>
        )
      }
      case "ERROR": {
        return (
          <p style={{ marginTop: sizes.margins.normal }}>
            {t("contact.error")}
          </p>
        )
      }
    }
  }

  render() {
    const { t } = this.props

    return (
      <div>
        <Form
          style={this.props.style}
          onSubmit={this.submitForm.bind(this)}
          action="https://formspree.io/mgevqqla"
          method="POST"
        >
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>{t("contact.email")}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder={t("contact.emailPlaceholder")}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{t("contact.message")}</Form.Label>
            <Form.Control as="textarea" name="message" rows={3} required />
          </Form.Group>
          <Form.Group>
            <Form.Check
              type="checkbox"
              onChange={(event: any) =>
                this.setState({ consentGiven: event.target.checked })
              }
              label={
                <div style={{ fontSize: "0.75em" }}>
                  {t("contact.agreement")}
                </div>
              }
              required
            />
          </Form.Group>
          {this.renderFormAction()}
        </Form>
      </div>
    )
  }

  submitForm(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault()
    const form = ev.currentTarget
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return
      } else if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}

export default withTranslation()(ContactFormPresenter)
