import React from "react"

const maphiLogoPath =
  "M1719.63,3585.83c97.78,-156.636 437.783,-554.911 337.046,-1368.65c-83.058,-670.934 -472.719,-837.796 -304.996,-1003.55c88.392,-87.353 329.962,50.493 423.093,127.249c337.845,278.446 459.497,973.948 -119.27,1314.94c-503.115,296.423 -1416.91,-15.007 -1674.5,-754.408c72.909,-36.603 219.429,-102.636 257.999,-296.635c17.153,-86.279 56.477,-518.755 541.864,-787.921c337.108,-186.938 646.367,-107.182 646.367,-107.182c200.279,-229.97 681.823,-556.02 1340.06,-359.308c81.683,312.341 68.672,857.843 -317.275,1206.54c408.495,136.017 606.582,474.428 606.582,474.428c-272.863,-48.294 -338.147,-59.632 -617.293,-79.416c172.186,96.991 474.053,193.699 663.313,550.602c0,0 -345.241,-238.243 -807.186,-164.659"

export function MaphiLineLogo(props: {
  color?: string
  width?: number | string
  height?: number | string
  style?: React.CSSProperties
}) {
  return (
    <svg
      width={props.width || "100%"}
      height={props.height || "100%"}
      style={props.style}
      viewBox="0 0 3884 3876"
    >
      <path
        fill="none"
        stroke={props.color || "white"}
        strokeWidth="200"
        strokeLinecap="round"
        strokeLinejoin="round"
        d={maphiLogoPath}
      />
    </svg>
  )
}
