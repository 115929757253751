import * as React from 'react'
import BambooSVG from './images/bamboo.svg'

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>

export const Bamboo = (props: Props) => {
  return <img src={BambooSVG} {...props} />
}
