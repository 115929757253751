import * as React from "react"

import "./index.css"
import { CommonLayoutProps } from "./common"
import { Header } from "../components/Header"
import { StickyContainer, Sticky } from "react-sticky"
import { Footer } from "../components/Footer"
import { useTranslation } from "react-i18next"
import { MaphiHelmet } from "../components/MaphiHelmet"

type LayoutProps = {
  floatingHeader?: boolean
  children: JSX.Element | JSX.Element[]
  title?: string
} & CommonLayoutProps

export const FloatingLayout = (props: LayoutProps) => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <MaphiHelmet title={props.title} />
      <StickyContainer>
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 10000,
          }}
        >
          <Sticky>
            {stickyProps => (
              <Header
                style={stickyProps.style}
                transparent={
                  props.floatingHeader &&
                  (!stickyProps.distanceFromTop ||
                    stickyProps.distanceFromTop > -100)
                }
              />
            )}
          </Sticky>
        </div>
        {props.children}
      </StickyContainer>
      <Footer />
    </div>
  )
}
