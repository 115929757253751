import * as React from "react"
import { graphql, StaticQuery, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImage = (name: string) => {
  const data = useStaticQuery(graphql`
    query FeaturedLogos {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "landing/features" }
        }
      ) {
        nodes {
          ...GetFeatureImage
        }
      }
    }

    fragment GetFeatureImage on File {
      name
      childImageSharp {
        fixed(width: 150, height: 100, fit: CONTAIN, background: "white") {
          width
          height
          src
          srcSet
        }
      }
    }
  `)

  const imgData = data.allFile.nodes.find((node: any) => node.name === name)

  if (!imgData) {
    console.error(`image not found: ${name}`)
    return <div />
  }

  return (
    <Img
      fixed={imgData.childImageSharp.fixed as any}
      imgStyle={{ objectFit: "contain" }}
      alt={name}
    />
  )
}

export const EXIST = () => getImage("EXIST")
export const Faktor = () => getImage("faktor")
export const Futurx = () => getImage("futurx")
export const GeorgAugust = () => getImage("georg-august")
export const Gesa = () => getImage("gesa")
export const IBB = () => getImage("IBB")
export const Kika = () => getImage("Kika")
export const NBank = () => getImage("nbank")
export const Pfh = () => getImage("pfh")
export const VentureVilla = () => getImage("VentureVilla")
export const NwIhk = () => getImage("nw-ihk")
export const KreHTiv = () => getImage("KreHTiv")
export const Dresinvest = () => getImage("dresinvest")
