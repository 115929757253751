import * as React from "react"
import { sizes } from "../layouts/sizes"
import { colors } from "../layouts/colors"
import { SpeechBubbleProps } from "../pages/index"

export const SpeechBubble = (props: SpeechBubbleProps) => {
  const faceSize = 50
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ flex: props.side === "left" ? 1 : 2 }} />
      <div
        style={{
          position: "relative",
          padding: sizes.margins.normal,
          backgroundColor: props.color,
          borderRadius: sizes.margins.normal,
          borderTopLeftRadius: faceSize * 0.75,
          [props.side === "left"
            ? "borderBottomLeftRadius"
            : "borderBottomRightRadius"]: 0,
          marginTop: sizes.margins.normal / 2,
          marginBottom: sizes.margins.normal / 2,
          float: props.side,
          flex: 5,
        }}
      >
        <div style={{ display: "flex", alignItems: "top" }}>
          <div
            style={{
              width: faceSize,
              height: faceSize,
              margin: sizes.margins.small,
            }}
          >
            {props.face}
          </div>
          <div
            style={{
              flex: 1,
              marginLeft: sizes.margins.normal,
              alignItems: "top",
            }}
          >
            <p style={{ color: colors.dominant.normal, margin: 0 }}>
              {props.name}
            </p>
            <p style={{ margin: 0 }}>"{props.text}"</p>
          </div>
        </div>
      </div>
      <div style={{ flex: props.side === "left" ? 2 : 1 }} />
    </div>
  )
}
