import * as React from "react"
import { graphql, StaticQuery, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImage = (name: string) => {
  const data = useStaticQuery(graphql`
    query TeamImages {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "landing/team" }
        }
      ) {
        nodes {
          ...GetTeamImage
        }
      }
    }

    fragment GetTeamImage on File {
      name
      childImageSharp {
        fixed(width: 150, height: 150) {
          width
          height
          src
          srcSet
        }
      }
    }
  `)

  const imgData = data.allFile.nodes.find((node: any) => node.name === name)

  if (!imgData) {
    console.error(`image not found: ${name}`)
    return <div />
  }

  return (
    <Img
      fixed={imgData.childImageSharp.fixed as any}
      imgStyle={{ borderRadius: imgData.childImageSharp.fixed.width / 2 }}
      alt={name.replace(/^\w/, c => c.toUpperCase())}
    />
  )
}

export const Lars = () => getImage("lars")
export const Tobias = () => getImage("tobias")
export const Christoph = () => getImage("christoph")
export const Veronika = () => getImage("veronika")
export const Nina = () => getImage("nina")
export const Jesse = () => getImage("jesse")
