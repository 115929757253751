import * as React from "react"
import { graphql } from "gatsby"
import { FloatingLayout } from "../layouts/FloatingLayout"
import { PromotionalVideoPreview } from "../components/PromotionalVideo"
import { sizes } from "../layouts/sizes"
import { colors } from "../layouts/colors"
import { FullWidthComponent } from "../components/FullWidthComponent"
import { AppStoreBadge, PlayStoreBadge } from "../components/appStoreBadges"
import { Bamboo } from "../components/bamboo"
import { useTranslation, Trans } from "react-i18next"
import { IPhoneXFrame } from "../components/DeviceFrame"
import Img from "gatsby-image"
import { MdStar, MdPerson } from "react-icons/md"
import { MaphiLineLogo } from "../components/MaphiLineLogo"

import { Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import { SpeechBubble } from "../components/SpeechBubble"
import {
  EXIST,
  GeorgAugust,
  IBB,
  Pfh,
  Futurx,
  Gesa,
  VentureVilla,
  NBank,
  Kika,
  Faktor,
  NwIhk,
  KreHTiv,
  Dresinvest,
} from "../components/FeatureLogos"
import {
  Lars,
  Tobias,
  Christoph,
  Veronika,
  Nina,
  Jesse,
} from "../components/TeamImages"
import TeamContactForm from "../components/ContactForm"
import appPreviewGif from "../data/preview.gif"
import { LocalizedPage, localizedPage } from "../i18n/Localized"

const sectionStyle: React.CSSProperties = {}
const graySectionStyle: React.CSSProperties = {
  ...sectionStyle,
  backgroundColor: colors.neutral.veryLight,
}
const greenSectionStyle: React.CSSProperties = {
  ...sectionStyle,
  backgroundColor: colors.dominant.lighter,
  color: "white",
}
const centerPadding: React.CSSProperties = {
  paddingTop: sizes.margins.larger,
  paddingBottom: sizes.margins.larger,
  paddingLeft: sizes.margins.normal,
  paddingRight: sizes.margins.normal,
}

const CallToAction = () => {
  const { t } = useTranslation()

  return (
    <div style={{ position: "relative", top: "-10vh" }}>
      <FullWidthComponent
        style={undefined}
        left={
          <div
            style={{
              backgroundColor: colors.dominant.lighter,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Bamboo
              style={{
                objectFit: "cover",
                objectPosition: "100% 0 ",
                position: "absolute",
                height: "100%",
                right: 0,
                top: 0,
                bottom: 0,
              }}
            />
          </div>
        }
        right={
          <div
            style={{
              borderBottomRightRadius: sizes.margins.large,
              borderTopRightRadius: sizes.margins.large,
              maxWidth: sizes.margins.large,
              backgroundColor: colors.dominant.lighter,
              height: "100%",
            }}
          />
        }
      >
        <div
          style={{
            backgroundColor: colors.dominant.lighter,
            color: "white",
            flex: 1,
            height: "100%",
            padding: sizes.margins.large,
            textAlign: "center",
          }}
        >
          <p
            style={{
              margin: sizes.margins.normal,
              textShadow: "0px 2px 2px rgba(0,0,0,0.5)",
              fontWeight: "bold",
              fontSize: "2em",
            }}
          >
            {t("landing.cta.title")}
          </p>
          <p style={{ fontFamily: "sans-serif", fontSize: "1.25em" }}>
            {t("landing.cta.subtitle")}
          </p>
          <div
            style={{
              justifyContent: "center",
              margin: sizes.margins.normal,
            }}
          >
            <a href="https://apps.apple.com/us/app/maphi-the-math-app/id1276181580">
              <AppStoreBadge
                style={{
                  margin: sizes.margins.normal,
                  height: 50,
                }}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.edutapps.maphi">
              <PlayStoreBadge
                style={{
                  margin: sizes.margins.normal,
                  height: 50,
                }}
              />
            </a>
          </div>
        </div>
        <div style={{ width: sizes.margins.normal }} />
      </FullWidthComponent>
    </div>
  )
}

const AnimatedAppPreview = () => {
  return (
    <div
      style={{
        height: 450,
        position: "relative",
        alignSelf: "center",
        marginBottom: sizes.margins.large,
      }}
    >
      <IPhoneXFrame style={{ position: "relative" }}>
        {style => (
          <img
            style={style}
            src={appPreviewGif}
            alt="Solve an equation through drag-and-drop gestures"
          />
        )}
      </IPhoneXFrame>
    </div>
  )
}

const AppPreview = () => {
  const { t } = useTranslation()

  return (
    <FullWidthComponent
      style={{
        ...sectionStyle,
        paddingTop: 100,
        marginTop: -100,
      }}
    >
      <div style={{ marginBottom: sizes.margins.veryLarge }}>
        <Row style={{ alignItems: "center" }}>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 8, order: 0 }}>
            <div
              style={{
                fontSize: "1.8em",
                fontWeight: 800,
                margin: sizes.margins.normal,
              }}
            >
              <Trans i18nKey="landing.preview.motivation">
                The best way to understand math is to try it yourself. The best
                way to try it is
                <span style={{ color: colors.highlight.normal }}>Maphi</span>.
              </Trans>
            </div>
            <Row>
              <Col md={6}>
                <div
                  style={{
                    flex: 1,
                    margin: sizes.margins.normal,
                    fontFamily: "sans-serif",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.25em",
                    }}
                  >
                    {t("landing.preview.block1.title")}
                  </span>{" "}
                  <br />
                  <span
                    style={{
                      fontSize: "1em",
                      color: colors.neutral.normal,
                    }}
                  >
                    {t("landing.preview.block1.text")}
                  </span>
                </div>
              </Col>
              <Col md={6}>
                <div
                  style={{
                    flex: 1,
                    margin: sizes.margins.normal,
                    fontFamily: "sans-serif",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.25em",
                    }}
                  >
                    {t("landing.preview.block2.title")}
                  </span>{" "}
                  <br />
                  <span
                    style={{
                      fontSize: "1em",
                      color: colors.neutral.normal,
                    }}
                  >
                    {t("landing.preview.block2.text")}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: "auto", order: 0 }}
            md={{ span: 4, order: 1 }}
            style={{ display: "flex", width: "100%" }}
          >
            <div style={{ flex: 1 }} />
            <AnimatedAppPreview />
            <div style={{ flex: 1 }} />
          </Col>
        </Row>
      </div>
    </FullWidthComponent>
  )
}

export interface SpeechBubbleProps {
  text: JSX.Element | string
  color: string
  side: "left" | "right"
  face: JSX.Element
  name: JSX.Element | string
}

function getFaceImagesFromData(data: any) {
  return {
    kiril: (
      <Img
        fixed={data.kiril.childImageSharp.fixed as any}
        imgStyle={{
          borderRadius: 25,
        }}
      />
    ),
    timur: (
      <Img
        fixed={data.timur.childImageSharp.fixed as any}
        imgStyle={{
          borderRadius: 25,
        }}
      />
    ),
    veronika: (
      <Img
        fixed={data.veronika.childImageSharp.fixed as any}
        imgStyle={{
          borderRadius: 25,
        }}
      />
    ),
  }
}

const CustomerFeedback = (props: { data: any }) => {
  const faces = getFaceImagesFromData(props.data)
  const { t } = useTranslation()

  return (
    <FullWidthComponent
      style={graySectionStyle}
      centerStyle={{
        zIndex: 1,
        display: "flex",
        flexWrap: "wrap",
        marginTop: sizes.margins.larger,
      }}
      right={
        <div
          style={{
            width: "100%",
            height: "100%",
            margin: 0,
            position: "relative",
          }}
        >
          <Bamboo
            style={{
              objectFit: "cover",
              position: "absolute",
              height: "100%",
              right: 0,
              top: 0,
              bottom: 0,
            }}
          />
        </div>
      }
    >
      <p style={{ flex: 1, textAlign: "center" }}>
        <span
          style={{
            fontSize: "2em",
            lineHeight: 1.2,
            margin: sizes.margins.normal,
          }}
        >
          <span style={{ color: colors.highlight.normal }}>
            4.9
            <br />
            <MdStar />
            <MdStar />
            <MdStar />
            <MdStar />
            <MdStar />
          </span>
          <br />
        </span>
        <span
          style={{
            fontSize: "1em",
            lineHeight: 2,
            color: colors.neutral.normal,
            flex: 1,
          }}
        >
          <span style={{ fontSize: "1.5em" }}>
            <MdPerson />
          </span>
          300+ total
        </span>
      </p>

      <Row>
        <Col xs={12} md={11}>
          <SpeechBubble
            side="left"
            text={t("landing.feedback.review1")}
            color="#D2E5B3"
            name={t("landing.feedback.user1")}
            face={faces.timur}
          />
          <br />
          <SpeechBubble
            side="right"
            text={t("landing.feedback.review2")}
            color="#F4CBAB"
            name={t("landing.feedback.user2")}
            face={faces.veronika}
          />
          <br />
          <SpeechBubble
            side="left"
            text={t("landing.feedback.review3")}
            color="#D1D6C8"
            name={t("landing.feedback.user3")}
            face={faces.kiril}
          />
          <div style={{ height: sizes.margins.larger }} />
        </Col>
        <Col xs={12} md={1}>
          <Img
            fixed={props.data.maphi.childImageSharp.fixed as any}
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              zIndex: 10,
            }}
            imgStyle={{ transform: "scaleX(-1)" }}
          />
        </Col>
      </Row>
    </FullWidthComponent>
  )
}

const Feature = (props: {
  name?: string | JSX.Element
  logo: JSX.Element
  link?: string
}) => {
  return (
    <Col
      md="auto"
      style={{
        width: 150,
        margin: sizes.margins.normal,
        display: "flex",
        justifyContent: "center",
        fontSize: "0.8em",
      }}
    >
      <a href={props.link} style={{ textDecoration: "none" }}>
        <div style={{ textAlign: "center" }}>
          <div style={{ padding: sizes.margins.normal }}>{props.logo}</div>
          {props.name && (
            <span
              style={{
                color: colors.neutral.normal,
                fontSize: "1em",
                marginTop: sizes.margins.normal,
              }}
            >
              {props.name}
            </span>
          )}
        </div>
      </a>
    </Col>
  )
}

const API = () => {
  return (
    <FullWidthComponent
      style={{
        ...sectionStyle,
        backgroundColor: colors.dominant.lighter,
        color: "white",
      }}
      centerStyle={centerPadding}
    >
      <p
        style={{
          fontWeight: "bold",
          fontSize: "1.5em",
          textAlign: "center",
          marginBottom: sizes.margins.large,
        }}
      >
        <Trans i18nKey="landing.api.title">The Maphi Algebra Engine</Trans>
      </p>
      <Row>
        <Col md="auto" style={{ display: "flex", justifyContent: "center" }}>
          <a href="https://engine.maphi.app">
            <MaphiLineLogo width={200} height={200} />
          </a>
        </Col>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1.25em",
            }}
          >
            <Trans i18nKey="landing.api.subtitle">
              Use Maphi in your projects
            </Trans>
          </div>
          <span
            style={{
              fontSize: "1em",
            }}
          >
            <Trans i18nKey="landing.api.text">
              {
                "Maphi's technology runs anywhere, on desktop, mobile apps and even on the web! Curious? "
              }
              <a style={{ color: "#FDBB26" }} href="#contact">
                Get in touch
              </a>
              {" or "}
              <a style={{ color: "#FDBB26" }} href="https://engine.maphi.app">
                Check out the live demo.
              </a>
            </Trans>
          </span>
        </Col>
      </Row>
    </FullWidthComponent>
  )
}

const FeaturedOn = () => {
  const { t } = useTranslation()

  return (
    <FullWidthComponent style={sectionStyle} centerStyle={centerPadding}>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "1.5em",
          textAlign: "center",
        }}
      >
        {t("landing.featured.supportedBy")}
      </p>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Feature
          name="Investment by DresInvest"
          logo={<Dresinvest />}
          link="https://dresinvest.de/edutapps"
        />
        <Feature
          name="VentureVilla Accelerator program"
          logo={<VentureVilla />}
          link="https://venturevilla.de/portfolio/"
        />
        <Feature
          name="EXIST Scholarship"
          logo={<EXIST />}
          link="https://www.exist.de/DE/Programm/Exist-Gruenderstipendium/Vorhabenkarte/EGSVorhaben/03EGSNI155.html"
        />
      </Row>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "1.5em",
          textAlign: "center",
          marginTop: sizes.margins.large,
        }}
      >
        {t("landing.featured.featuredOn")}
      </p>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Feature
          name="Winner of the Lift-Off Pitch Competition at University of Göttingen 2017"
          logo={<GeorgAugust />}
          link="https://www.uni-goettingen.de/de/568430.html"
        />
        <Feature
          name="Winner PFH Enterpreneurship School 2018"
          logo={<Pfh />}
          link="https://www.pfh.de/aktuelles/771-clevere-loesungen-mini-helikopter-und-mathe-app.html"
        />
        <Feature
          name="Winner PFH FUTUR X PitchBoXing 2018"
          logo={<Futurx />}
          link="https://twitter.com/futurxgmbh/status/1027190174747451392"
        />
        <Feature
          name="German nominee and winner of the innovation Track at the Global EdTech Awards 2018 in London"
          logo={<Gesa />}
          link="https://www.globaledtechawards.org/winners2018"
        />
        <Feature
          name="Winner of the NCapital Matching Pitch at CEBIT 2018"
          logo={<NBank />}
          link="https://www.nbank.de/Blickpunkt/NCapital-Matching-CEBIT-2018.jsp"
        />
      </Row>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "1.5em",
          textAlign: "center",
          marginTop: sizes.margins.large,
        }}
      >
        {t("landing.featured.inPress")}
      </p>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Feature
          logo={<Kika />}
          link="https://www.kika.de/timster/sendungen/sendung107968.html"
        />
        <Feature
          logo={<Faktor />}
          link="https://www.faktor-magazin.de/zahlen-fuer-die-couch/"
        />
        <Feature
          logo={<NwIhk />}
          link="https://nw-ihk.de/2018/09/mathe-app-maphi/"
        />
        <Feature
          logo={<KreHTiv />}
          link="https://www.krehtiv.de/aktuelles/blog/detail/mit-dem-handy-mathe-spielen-nachsitzen-beim-ideenboulevard-2018/"
        />
      </Row>
    </FullWidthComponent>
  )
}

const TeamMember = (props: {
  image: JSX.Element
  name: string
  role: string
  link?: string
}) => {
  return (
    <Col md="auto" style={{ margin: sizes.margins.large }}>
      <a href={props.link} style={{ textDecoration: "none", color: "black" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {props.image}
        </div>
        <div
          style={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.25em",
          }}
        >
          {props.name}
        </div>
        <div style={{ textAlign: "center" }}>{props.role}</div>
      </a>
    </Col>
  )
}

const Team = () => {
  const { t } = useTranslation()

  return (
    <FullWidthComponent style={graySectionStyle} centerStyle={centerPadding}>
      <p
        style={{
          fontWeight: "bold",
          fontSize: "1.5em",
          textAlign: "center",
        }}
      >
        {t("landing.team.title")}
      </p>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <TeamMember
          name="Lars Melchior"
          role="Founder | Tech Lead"
          image={<Lars />}
          link="https://thelartians.github.io/"
        />
        <TeamMember
          name="Tobias Jahn"
          role="Founder"
          image={<Tobias />}
          link="https://www.linkedin.com/in/tobias-jahn-03287b129/"
        />
        <TeamMember
          name="Christoph Stiller"
          role="Founder"
          image={<Christoph />}
          link="https://www.linkedin.com/in/christoph-stiller/"
        />
      </Row>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <TeamMember
          name="Veronika Soláriková"
          role="Illustrations"
          image={<Veronika />}
          link="https://www.linkedin.com/in/veronika-solaris-b2a61210b/"
        />
        <TeamMember
          name="Nina Klymenko"
          role="UX Design"
          image={<Nina />}
          link="https://www.linkedin.com/in/ninaklymenko/"
        />
        <TeamMember
          name="Jesse Duplechain"
          role="Sound Design"
          image={<Jesse />}
          link="https://www.linkedin.com/in/jesse-duplechain/"
        />
      </Row>
    </FullWidthComponent>
  )
}

const Contact = () => {
  const { t } = useTranslation()
  return (
    <FullWidthComponent
      style={greenSectionStyle}
      centerStyle={{ width: "100%", ...centerPadding }}
    >
      <div
        id="contact"
        style={{
          fontSize: "1.5em",
          textAlign: "center",
          marginBottom: sizes.margins.large,
        }}
      >
        {t("landing.contact.title")}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TeamContactForm style={{ maxWidth: sizes.pageWidth / 2 }} />
      </div>
    </FullWidthComponent>
  )
}

interface IndexPageProps {
  data: any
}

export default localizedPage((props: IndexPageProps) => {
  return (
    <FloatingLayout floatingHeader={true}>
      <PromotionalVideoPreview />
      <CallToAction />
      <AppPreview />
      <CustomerFeedback data={props.data} />
      <API />
      <FeaturedOn />
      <Team />
      <Contact />
    </FloatingLayout>
  )
})

export const pageQuery = graphql`
  query LandingSite {
    site {
      siteMetadata {
        title
      }
    }

    kiril: file(relativePath: { regex: "/landing/" }, name: { eq: "kiril" }) {
      ...GetFace
    }

    veronika: file(
      relativePath: { regex: "/landing/" }
      name: { eq: "veronika" }
    ) {
      ...GetFace
    }

    timur: file(relativePath: { regex: "/landing/" }, name: { eq: "timur" }) {
      ...GetFace
    }

    maphi: file(relativePath: { regex: "/maphi/" }, name: { eq: "Maphi" }) {
      childImageSharp {
        fixed(width: 150, height: 100, cropFocus: NORTH) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }
  }

  fragment GetFace on File {
    name
    childImageSharp {
      fixed(width: 50, height: 50) {
        base64
        width
        height
        src
        srcSet
      }
    }
  }
`
