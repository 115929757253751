import * as React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { colors } from "../layouts/colors"

export const PromotionalVideoPreview = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          file(relativePath: { eq: "landing/AdobeStock_57845683.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 3840) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <div style={{ backgroundColor: colors.dominant.normal }}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            imgStyle={{ opacity: 0.5 }}
            style={{ height: "50vh" }}
          />
        </div>
      )}
    />
  )
}
